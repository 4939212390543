<style scoped>
a {
  text-decoration: none;
}
</style>

<template>
  <v-container fluid>
    <v-row no-gutters justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-img contain height="107" src="/img/login/logo.jpg"></v-img>
        </v-card>
        <v-card-title class="display-2 font-weight-regular primary--text pb-10">
          Willkommen bei der Benutzerverwaltung<br/>für das DRK
        </v-card-title>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3">
        <v-card flat outlined>
          <v-carousel
            cycle
            hide-delimiters
            show-arrows-on-hover
            continuous
            touchless
            dark
          >
            <v-carousel-item
              v-for="(slide, i) in slides.slides"
              :key="i"
              :src="slide"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-flex flex-column">
        <div>
          <span class="primary--text font-weight-light display-1">Anmelden</span>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              :rules="[rules.required]"
              autofocus
              v-model="username"
              label="Benutzername"
            ></v-text-field>
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              label="Passwort"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              depressed
              color="secondary"
              class="darken-3"
              tile
              @click="handleSubmit"
            >
              Anmelden
            </v-btn>
          </v-form>
        </div>
        <div class="my-8">
          <p>Noch kein Login? Anmeldung <a title="Anmeldung" @click.stop="showApplication = true">hier</a>.</p>
          <v-btn
            text
            color="error"
            @click.stop="showPasswordLost = true"
          >
            Kennwort vergessen?
          </v-btn>
          <p><a href="https://benutzerverwaltung.drk-db.de/passwordLost.html" title="Passwort vergessen?" target="_blank"></a></p>
          <p>Bei Problemen mit dem Passwort hilft Ihnen unsere Login-Hotline weiter: <a href="tel:+4930868778229">030 868 778 229</a> Mo, Di und Do in der Zeit von 09:00 Uhr bis 14:00 Uhr oder per Mail: <a href="mailto:login@drkservice.de">login@drkservice.de</a></p>
          <v-card flat class="pa-5" href="https://www.drk-intern.de/schulungen/bvw-schulung.html" target="_blank">
              <v-img contain height="76" src="/img/login/teaser-bvw.png" alt="Schulung BVW 2.0"></v-img>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-row>
          <v-col cols="2"><span class="font-weight-bold subtitle-2 text--darken-3 grey--text">Informieren</span></v-col>
          <v-col cols="2"><span class="font-weight-bold subtitle-2 text--darken-3 grey--text">Service</span></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <div class="d-flex flex-column">
              <a
                href="https://www.drk-intern.de/news/news.html"
                title="Aktuelles"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >Aktuelles</a>
              <a
                href="http://www.drk.de/"
                title="DRK.de"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >DRK.de</a>
              <a
                href="https://www.drkservice.de/"
                title="DRK-Service GmbH"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >DRK-Service GmbH</a>
              <p class="font-weight-thin text-caption">ver {{version}}</p>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex flex-column">
              <a
                href="https://www.drk-intern.de/sitemap.html"
                title="Sitemap"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >Sitemap</a>
              <a
                href="https://www.drk-intern.de/impressum.html"
                title="Impressum"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >Impressum</a>
              <a
                href="https://www.drk-intern.de/datenschutz.html"
                title="Datenschutz"
                target="_blank"
                class="body-2 text--darken-3 grey--text"
              >Datenschutz</a>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <password-lost
      v-model="showPasswordLost"
    ></password-lost>
    <new-application
      v-model="showApplication"
    ></new-application>
    <v-dialog
      v-model="showLoginError"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Fehler beim Login</v-card-title>
        <v-card-text>Benutzername und/oder Passwort falsch.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" small @click="showLoginError = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PasswordLost from "./PasswordLost";
import NewApplication from "./application/NewApplication";

export default {
  components: {
    PasswordLost,
    NewApplication
  },
  data () {
    return {
      valid: false,
      username: '',
      password: '',
      showPassword: false,
      showPasswordLost: false,
      showApplication: false,
      showLoginError: false,
      rules: {
        required: value => !!value || 'Benötigt.'
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.checkParam()
    })
  },
  computed: {
    ...mapState({
      slides: state => state.slides,
      status: state => state.account.status
    }),
    ...mapGetters({
      version: 'app/getVersionString'
    }),
  },
  watch: {
    status() {
      this.showLoginError = !!this.status.loginError
    }
  },
  methods: {
    ...mapActions('account', ['login', 'loginByTicket', 'logout']),
    ...mapActions('slides', ['load']),
    ...mapActions('app', ['getVersion']),
    handleSubmit () {
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password })
      }
    },
    checkParam() {
      if (this.$route.params.param && 'register' === this.$route.params.param) {
        this.showApplication = true
      }
      if (this.$route.params.param && 'password-lost' === this.$route.params.param) {
        this.showPasswordLost = true
      }
      if (this.$route.query && this.$route.query.ticket) {
        this.loginByTicket(this.$route.query.ticket)
      }
    }
  },
  created () {
    this.load()
    this.getVersion()
  }
};
</script>
